
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

.header {

	$random-banners: (family, property, wills);

	@each $banner in $random-banners {

		&.#{$banner} {

			background-image: url('../images/home-banners/#{$banner}.jpg');

			.tagline{

				.#{$banner} {

					display: block;
				}
			}
		}
	}

	background-position: center right;
	background-repeat: no-repeat;
	background-size: cover;

	@media #{$tabletXL} {
		height: 660px;
	}

	.tagline {

		p {
			display: none;
		}
	}
}

.hero {
	padding: $block-margin $block-padding 0 $block-padding;

	@media #{$tablet} {
		margin-bottom: -120px;
	}

	@media #{$tabletXL} {
		margin-bottom: -290px;
	}

	.inner {
		padding: 0;
	}
}

@media #{$tablet} {

	.service-buttons-container,
	.form-container {

		.block {
			margin-bottom: 0;
		}

		@include fraction((
			grid: 	true,
			columns: 2,
			gutter: $block-margin,
			ie-true: true,
		));
	}
}

@media #{$tabletXL} {

	.form-container {

		.block {
			min-height: 242px;
		}
	}
}

@media #{$laptop} {

	.service-buttons-container,
	.form-container {

		@include fraction((
			grid: 	true,
			columns: 2,
			gutter: $block-margin * 2,
			ie-true: true,
		));
	}
}

.service-buttons-container {
	@extend %clear-fix;

	ul {
		background: $color-white;
		box-shadow: 0px 0px 12px 5px $color-midgrey;
		@extend %clear-fix;

		li {
			display: block;
			height: 120px;
			position: relative;

			&:nth-child(odd){
				background: #e5f2fa;
			}

			a {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				color: $color-primary;
				font-family: $font-family-title;

				&:hover {
					color: $color-tertiary;

					.text {
						bottom: $block-margin * 1.1;
					}
				}
			}

			.text {
				display: block;
				padding: 0 $block-margin 0 $block-margin*1.5;
				position: absolute;
				bottom: $block-margin;
				transition: $transition;

				span {
					position:relative;

					&:before {
						content:' ';
						display: block;
						position: absolute;
						background: url('../images/service-dots.png') no-repeat center center;
						left: -$block-padding * 1.8;
						width: 30px;
						height: 35px;
						@include centreY();
					}
				}
			}
		}

		@media #{$tablet} {
			transform: translateY(-120px);

			li {
				background: $color-white;
			}
		}


		@media #{$tabletXL} {
			transform: translateY(-290px);

			li {
				background: $color-white;
				height: 290px;

				&:nth-child(1),
				&:nth-child(4) {
					background: #e5f2fa;
				}

				&:nth-child(2){
					background: url('../images/panel-dots.png') no-repeat -20% -15%;
					background-size: 150px auto;

				}

				&:nth-child(3){
					background: url('../images/panel-dots.png') no-repeat  105% -15%;
					background-size: 180px auto;
				}

				@include fraction((
					grid: 	true,
					columns: 2,
					ie-true: true,
				));
			}
		}

		@media #{$laptop} {

			li {

				a {
					font-size: $h4;
					font-weight: $font-bold;

					&:hover{
						color: $color-tertiary;
						font-size: $h4;
					}
				}
			}
		}
	}
}

.block-text {
	text-align: center;
	position: relative;
	margin-bottom: 0;

	&:before {
		content:'';
		display: block;
		width: 100%;
		height: 0;
		border-bottom: 1px solid rgba($color-midgrey, 0.5);
		position: relative;
		top: 18px;
	}

	h1 {
		font-size: $h3;
		display: inline-block;
		background: $color-white;
		padding: 0 $block-padding/2;
		position: relative;
		z-index: 1;
		margin-bottom: $block-padding/2;
	}

	.content {
		>p {
			font-weight: $font-bold;
			font-size: $font-size-body - 0.2rem;
			letter-spacing: 2px;
			margin-bottom: 0;

			span {
				text-transform: uppercase;
			}
		}

		.bx-viewport{
			padding: $block-padding ;
		}

		@media #{$tablet}{

			.bx-viewport{
				padding: $block-margin  ;
			}

			.bx-wrapper {

				p {
					font-size:$h5;
				}
			}
		}

		@media #{$maxWidth}{

			.bx-viewport{
				padding: $block-margin $block-margin * 2 ;
			}

			.bx-wrapper {

				p {
					font-size:$h5;
				}
			}
		}
	}

	.bx-has-pager {
		// margin-top: $block-padding;

		&:before {
			content:'';
			display: block;
			width: 100%;
			height: 0;
			border-bottom: 1px solid rgba($color-midgrey, 0.5);
			position: relative;
			top: 21px;
		}

		.bx-pager {
			display: inline-block;
			background-color: $color-white;
			position: relative;
			z-index: 1;
		}

		.bx-pager-item {
			display: inline-block;
    		margin: $block-padding/2 $block-padding/4;

			a {
				display: block;
			    width: 18px;
			    height: 18px;
			    border-radius: 50%;
			    background: $color-lightgrey-dark;
			    color: transparent;
			    text-align: center;
			    text-indent: -9999999999px;

				&.active,
			    &:hover {
			    	background: $color-secondary;
			    }
			}

		}
	}
}
