html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.service-buttons-container:after, .service-buttons-container ul:after {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden; }

.hidden {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.header {
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover; }
  .header.family {
    background-image: url("../images/home-banners/family.jpg"); }
    .header.family .tagline .family {
      display: block; }
  .header.property {
    background-image: url("../images/home-banners/property.jpg"); }
    .header.property .tagline .property {
      display: block; }
  .header.wills {
    background-image: url("../images/home-banners/wills.jpg"); }
    .header.wills .tagline .wills {
      display: block; }
  @media only screen and (min-width: 60em) {
    .header {
      height: 660px; } }
  .header .tagline p {
    display: none; }

.hero {
  padding: 3rem 1.5rem 0 1.5rem; }
  @media only screen and (min-width: 50em) {
    .hero {
      margin-bottom: -120px; } }
  @media only screen and (min-width: 60em) {
    .hero {
      margin-bottom: -290px; } }
  .hero .inner {
    padding: 0; }

@media only screen and (min-width: 50em) {
  .service-buttons-container,
  .form-container {
    float: left; }
    .service-buttons-container .block,
    .form-container .block {
      margin-bottom: 0; }
    .service-buttons-container:nth-child(n),
    .form-container:nth-child(n) {
      clear: none;
      width: calc((100% - 3rem) / 2);
      margin: 0 0 0 3rem; }
    .service-buttons-container:nth-child(2n+1),
    .form-container:nth-child(2n+1) {
      clear: both;
      margin-left: 0; } }

@media only screen and (min-width: 60em) {
  .form-container .block {
    min-height: 242px; } }

@media only screen and (min-width: 70em) {
  .service-buttons-container,
  .form-container {
    float: left; }
    .service-buttons-container:nth-child(n),
    .form-container:nth-child(n) {
      clear: none;
      width: calc((100% - 6rem) / 2);
      margin: 0 0 0 6rem; }
    .service-buttons-container:nth-child(2n+1),
    .form-container:nth-child(2n+1) {
      clear: both;
      margin-left: 0; } }

.service-buttons-container ul {
  background: #fff;
  box-shadow: 0px 0px 12px 5px #bbb; }
  .service-buttons-container ul li {
    display: block;
    height: 120px;
    position: relative; }
    .service-buttons-container ul li:nth-child(odd) {
      background: #e5f2fa; }
    .service-buttons-container ul li a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #003050;
      font-family: "Roboto Slab", serif; }
      .service-buttons-container ul li a:hover {
        color: #0086c5; }
        .service-buttons-container ul li a:hover .text {
          bottom: 3.3rem; }
    .service-buttons-container ul li .text {
      display: block;
      padding: 0 3rem 0 4.5rem;
      position: absolute;
      bottom: 3rem;
      transition: all 0.3s ease-out; }
      .service-buttons-container ul li .text span {
        position: relative; }
        .service-buttons-container ul li .text span:before {
          content: ' ';
          display: block;
          position: absolute;
          background: url("../images/service-dots.png") no-repeat center center;
          left: -2.7rem;
          width: 30px;
          height: 35px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%); }
  @media only screen and (min-width: 50em) {
    .service-buttons-container ul {
      transform: translateY(-120px); }
      .service-buttons-container ul li {
        background: #fff; } }
  @media only screen and (min-width: 60em) {
    .service-buttons-container ul {
      transform: translateY(-290px); }
      .service-buttons-container ul li {
        background: #fff;
        height: 290px;
        float: left; }
        .service-buttons-container ul li:nth-child(1), .service-buttons-container ul li:nth-child(4) {
          background: #e5f2fa; }
        .service-buttons-container ul li:nth-child(2) {
          background: url("../images/panel-dots.png") no-repeat -20% -15%;
          background-size: 150px auto; }
        .service-buttons-container ul li:nth-child(3) {
          background: url("../images/panel-dots.png") no-repeat 105% -15%;
          background-size: 180px auto; }
        .service-buttons-container ul li:nth-child(n) {
          clear: none;
          width: 50%; }
        .service-buttons-container ul li:nth-child(2n+1) {
          clear: both;
          margin-left: 0; } }
  @media only screen and (min-width: 70em) {
    .service-buttons-container ul li a {
      font-size: 1.4rem;
      font-weight: 700; }
      .service-buttons-container ul li a:hover {
        color: #0086c5;
        font-size: 1.4rem; } }

.block-text {
  text-align: center;
  position: relative;
  margin-bottom: 0; }
  .block-text:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
    position: relative;
    top: 18px; }
  .block-text h1 {
    font-size: 1.6rem;
    display: inline-block;
    background: #fff;
    padding: 0 0.75rem;
    position: relative;
    z-index: 1;
    margin-bottom: 0.75rem; }
  .block-text .content > p {
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 2px;
    margin-bottom: 0; }
    .block-text .content > p span {
      text-transform: uppercase; }
  .block-text .content .bx-viewport {
    padding: 1.5rem; }
  @media only screen and (min-width: 50em) {
    .block-text .content .bx-viewport {
      padding: 3rem; }
    .block-text .content .bx-wrapper p {
      font-size: 1.2rem; } }
  @media only screen and (min-width: 81.25em) {
    .block-text .content .bx-viewport {
      padding: 3rem 6rem; }
    .block-text .content .bx-wrapper p {
      font-size: 1.2rem; } }
  .block-text .bx-has-pager:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
    position: relative;
    top: 21px; }
  .block-text .bx-has-pager .bx-pager {
    display: inline-block;
    background-color: #fff;
    position: relative;
    z-index: 1; }
  .block-text .bx-has-pager .bx-pager-item {
    display: inline-block;
    margin: 0.75rem 0.375rem; }
    .block-text .bx-has-pager .bx-pager-item a {
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #e1e1e1;
      color: transparent;
      text-align: center;
      text-indent: -9999999999px; }
      .block-text .bx-has-pager .bx-pager-item a.active, .block-text .bx-has-pager .bx-pager-item a:hover {
        background: #bed600; }
